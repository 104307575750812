@import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '@fortawesome/fontawesome-free/scss/brands';
@import '@fortawesome/fontawesome-free/scss/solid';

// Font
$font-family-sans-serif: 'Inter', sans-serif;

// Color
$primary: #d35400;
// $primary: #11abb0;
$secondary: #d35400;
$dark: rgb(30, 30, 30);
$light: #eff0f0;
$gray-700: #495057;
$body-color: rgba(255, 255, 255, 0.5);
$text-secondary-color: #fff6;
// $dark-text: red;
// $body-color-dark: red;

$white: #fff;

// Spacing
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 6,
  7: $spacer * 8,
  8: $spacer * 12,
) !default;

$line-height-base: 2;

// Navbar
$navbar-nav-link-padding-x: 1.5rem;
$navbar-padding-y: 1rem;
$navbar-dark-color: $body-color;
$navbar-dark-hover-color: $white;
$navbar-dark-active-color: $secondary;

// Buttons
$button-radius: 50px;
$button-padding-x: 30px;
$button-padding-y: 12px;

// $btn-border-radius: $button-radius;
// $btn-border-radius-sm: $button-radius;
// $btn-border-radius-lg: $button-radius;
$btn-padding-y: $button-padding-y;
$btn-padding-y-lg: $button-padding-y;
$btn-padding-y-sm: $button-padding-y;
$btn-padding-x: $button-padding-x;
$btn-padding-x-lg: $button-padding-x;
$btn-padding-x-sm: $button-padding-x;

// $navbar-toggler-border-radius: 5px;

// Container
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1000px,
  xl: 1000px,
  xxl: 1140px,
) !default;

$input-color: $light;
$input-placeholder-color: $gray-700;

@import 'bootstrap/scss/bootstrap.scss';

html {
  font-size: 112.5%;
}

.header {
  background: url('../images/intro-bg.jpg') center center no-repeat;
  background-size: cover;
}

.header::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.header .roles {
  font-family: 'IBM Plex Serif', serif;
  color: rgba(255, 255, 255, 0.5);
}

.project:hover {
  transform: scale(1.05);
  transition: all 0.5s ease-in-out;
  opacity: 0.8;
}

#services {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5)),
    url('../images/bg.jpg') center center no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.btn-outline-light:hover,
.btn-light:hover {
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: var(--bs-secondary);
  --bs-btn-hover-border-color: var(--bs-secondary);
}

.letter-spacing-wide {
  letter-spacing: 0.28rem;
}

.font-secondary {
  font-family: 'IBM Plex Serif', serif;
}

@media (max-width: 992px) {
  .navbar {
    background: var(--bs-black);
  }
}
